



















































import { computed, defineComponent, ref, useFetch, useRoute } from '@nuxtjs/composition-api';
import { useUserOrder } from '~/modules/customer/composables/useUserOrder';
import useCart from '~/modules/checkout/composables/useCart';
import { SfLoader } from '@storefront-ui/vue';
import PictimeOrderInformationAddressColumn
  from '~/modules/customer/pages/MyAccount/OrderHistory/SingleOrder/PictimeOrderInformationAddressColumn.vue';
import SvgImage from '~/components/General/SvgImage.vue';
import { cachePrice } from '~/helpers/customer/role';

export default defineComponent({
  layout: 'full-width',
  components: {
    SfLoader,
    SvgImage,
    PictimeOrderInformationAddressColumn
  },
  setup() {
    const route = useRoute();
    const { search } = useUserOrder();
    const identifier = ref('');
    const orderId = ref('');
    const orderTotal = ref('');
    const shipping_address = ref('');
    const orderoK = ref(false);
    const { canViewPrice } = cachePrice();

    //&& yarn build && yarn start:local
    useFetch(async () => {
      const orderNumber = computed(() => route.value.query.order ?? '');
      const orderResult = await search({ filter: { number: { eq: orderNumber.value } } });
      const order = orderResult.items[0] ?? null;
      orderId.value = order.number;
      orderTotal.value = order.total.base_grand_total.value;
      shipping_address.value = order.shipping_address;
      if (order.shipping_method == 'Retrait - Retrait en magasin') {
        identifier.value = 'text-shipping-store'
      } else {
        identifier.value = 'text-shipping-delivery'
      }
      orderoK.value = true;

    });
    return {
      orderId,
      orderTotal,
      shipping_address,
      identifier,
      orderoK,
      canViewPrice
    };
  },
});
