






















import { defineComponent, PropType } from '@nuxtjs/composition-api';
import type { OrderAddress } from '~/modules/GraphQL/types';
import ContentBlocks from '../../../../../../components/ContentBlocks';

export default defineComponent({
  components: {
    ContentBlocks,
  },
  props: {
    address: {
      type: Object as PropType<OrderAddress>,
      required: true,
    },
    identifier: {
      type: String,
      default: 'text-shipping-store'
    }
  },
});
